<template>
  <div>
    <div class="page">
      <div class="loginPage">
        <div class="title">验证码登录</div>
        <div class="dot"></div>
        <div class="item1" style="margin-bottom: 30px">
          <img
            class="ico"
            slot="prefix"
            style="width: 10px; height: 17px"
            src="@/assets/tel.png"
            alt=""
          />
          <input
            style="padding: 1px 15px"
            type="number"
            placeholder="请输入手机号"
            v-model="tel"
          />
        </div>
        <div class="item1" style="margin-bottom: 15px">
          <img
            class="ico"
            slot="prefix"
            style="width: 14px; height: 15px"
            src="@/assets/pass.png"
            alt=""
          />
          <input
            style="width: 120px; padding: 1px 12px"
            type="number"
            placeholder="请输入验证码"
            v-model.number="code"
          />
          <div class="second" @click="getCode">
            {{ start ? num + "s" : "点击获取验证码" }}
          </div>
        </div>

        <div
          class="item"
          style="margin-bottom: 35px; font-size: 11px; color: #08243c"
        >
          <el-checkbox v-model="checked" class="agreement"
            >阅读并同意
            <span @click="goClause('Clause')">《条款协议》</span>
          </el-checkbox>
        </div>

        <div
          class="item"
          style="text-align: center; margin-bottom: 50px"
          @click="login"
        >
          <el-button class="login">登录</el-button>
        </div>

        <el-divider><span class="sanfang">第三方登录</span></el-divider>

        <div class="wx" @click="show">
          <img src="@/assets/wx.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  sendCode,
  login,
  wechatMobileLogin,
  wxPublicCodeLogin,
} from "@/request/api.js";
export default {
  data() {
    return {
      tel: "",
      code: "",
      start: false,
      num: 59,
      checked: false,
      wxCode: "",
      incode: "",
    };
  },

  created() {
    this.getWxCode();
    if (this.$route.query.invitation_code) {
      this.incode = this.$route.query.invitation_code;
    }
  },

  methods: {
    //获取验证码
    getCode() {
      if (this.start) {
        return;
      }
      sendCode({
        tel: this.tel,
      }).then((res) => {
        if (res.code == 200) {
          this.start = !this.start;
          var t = this.num;
          var time = setInterval(() => {
            this.num = t--;
            if (this.num == 0) {
              clearInterval(time);
              this.start = !this.start;
              this.num = 60;
            }
          }, 1000);
        }

        if (res.code == 500) {
          this.$message.error(res.msg);
        }
      });
    },
    go(url) {
      this.$router.push({
        name: url,
      });
    },
    goClause(url) {
      this.$router.push({
        name: url,
        query: {
          type: "loginterms",
        },
      });
    },
    login() {
      console.log(1111);

      if (this.tel == "" || this.tel == null) {
        this.$message.error("请输入手机号");
        return;
      }

      if (this.code == "" || this.code == null) {
        this.$message.error("请输入验证码");
        return;
      }

      if (!this.checked) {
        this.$message.error("请勾选条款协议");
        return;
      }

      console.log(this.checked);

      login({
        tel: this.tel,
        code: this.code,
        cheched: this.checked,
        invitation_code: this.incode,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          localStorage.setItem("head_pic", res.data.head_pic);
          localStorage.setItem("nickname", res.data.nickname);
          this.$router.go(-1);
        }

        if (res.code == 500) {
          this.$message.error(res.msg);
        }
      });
    },
    //微信ewm
    show() {
      if (!this.checked) {
        this.$message.error("请勾选条款协议");
        return;
      }

      wechatMobileLogin({}).then((res) => {
        console.log(res);
        if (res.code == 200) {
          window.location.href = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getWxCode() {
      if (!this.$route.query.code) {
        return;
      }

      this.wxCode = this.$route.query.code;

      wxPublicCodeLogin({
        code: this.wxCode,
      }).then((res) => {
        if (res.code == 200) {
          localStorage.setItem("iswx", 1);
          localStorage.setItem("head_pic", res.data.head_pic);
          localStorage.setItem("nickname", res.data.nickname);
          this.$router.go(-2);
        }

        if (res.code == 500) {
          localStorage.setItem("iswx", 0);

          this.$message.error(res.msg);
          let path = this.$route.path; //先获取路由路径
          this.$router.go(path);
        }
      });
    },
  },
};
</script>

<style lang="less">
body {
  position: relative;
  background: url("../../assets/loginbeij.png") no-repeat;
  background-size: 100% 100%;
  background-size: cover;
  // margin-left: 8%;
}

.page {
  background-color: #ffffff;
  // border: 1px red solid;
  .loginPage {
    margin-top: 130px;
    padding: 22px 28px;
    .title {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: #08243c;
      margin-bottom: ;
    }
    .dot {
      background-color: #e66617;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin: 8px 49% 30px;
    }
    .item {
      .agreement {
        font-size: 12px;
        font-weight: 400;
        color: #000000;
      }

      .login {
        width: 230px;
        height: 40px;
        background: #08243c;
        border-radius: 20px;
        font-size: 15px;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .item1 {
      display: flex;
      align-items: center;
      padding: 8px 9px;
      border-bottom: 1px solid #dfdfdf;
      input {
        background: none;
        outline: none;
        border: none;
      }
      .second {
        font-size: 12px;
        font-weight: 400;
        color: #e66617;
        cursor: pointer;
      }
    }
    .sanfang {
      font-size: 12px;
      font-weight: 400;
      color: #888888;
      line-height: 12px;
    }

    .wx {
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
